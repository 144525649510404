/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202408222048-CDH
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqFormulasApiClass {
 
   constructor() {}

  /**
   * @summary Clear all formula caches. This method is deprecated
   */
  public clearCache(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/clearCache`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Compile a Seeq Formula. This method is deprecated.
   * @param {string} [formula] - A formula to be applied.
   * @param {Array<string>} [parameters] - Parameters for the formula. Each parameter should have a format of &#x27;name&#x3D;id&#x27; where &#x27;name&#x27; is the variable identifier, without the leading $ sign, and &#x27;id&#x27; is the ID of the item referenced by the variable.
   */
  public compileFormula(
    {
      formula,
      parameters
    } : {
      formula?: string,
      parameters?: Array<string>
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/compile`,

      params: omitBy({
        ['formula']: formula,
        ['parameters']: parameters
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaCompileOutputV1>;
  }

  /**
   * @summary Compile a Seeq Formula. Use this endpoint instead of GET /compile when the input is large.
   */
  public compileFormulaAndParameters(
    body: models.FormulaCompileInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/compile`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaCompileOutputV1>;
  }

  /**
   * @summary Create a function
   */
  public createFunction(
    body: models.FunctionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/functions`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * @summary Create a formula item
   */
  public createItem(
    body: models.FormulaItemInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/items`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaItemOutputV1>;
  }

  /**
   * @summary Permanently delete the specified formula doc
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the formula doc
   */
  public deleteFormulaDoc(
    {
      packageName,
      docName
    } : {
      packageName: string,
      docName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/formulas/docs/${encodeURIComponent(String(packageName))}/${encodeURIComponent(String(docName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Permanently delete a specific user defined formula function
   * @param {string} id - The id of the user defined formula function
   */
  public deleteFunction(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/formulas/functions/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Permanently delete a specific package
   * @param {string} packageName - The name of the package
   */
  public deletePackage(
    {
      packageName
    } : {
      packageName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/formulas/packages/${encodeURIComponent(String(packageName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get a list of the constants available for use in a calculation
   */
  public getConstantNameList(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/docs/constants`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<Array<string>>;
  }

  /**
   * @summary Get documentation details about a specific function group or index
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the document for the desired function or index
   */
  public getFormulaDoc(
    {
      packageName,
      docName
    } : {
      packageName: string,
      docName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/docs/${encodeURIComponent(String(packageName))}/${encodeURIComponent(String(docName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaDocOutputV1>;
  }

  /**
   * @summary Get a list of the functions available for use in a calculation
   */
  public getFormulaDocs(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/docs`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaDocSummariesOutputV1>;
  }

  /**
   * @summary Get a collection of Formula Functions
   * @param {string} [type=FormulaFunction] - The type of Formula Functions that should be included. Valid options are FormulaFunction, AggregatingFormulaFunction, Chart, and UserDefinedFormulaFunction
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getFormulaFunctions(
    {
      type,
      offset,
      limit
    } : {
      type?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/functions`,

      params: omitBy({
        ['type']: type,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get a Formula Function
   * @param {string} id - ID of the function
   */
  public getFunction(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/functions/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CalculatedItemOutputV1>;
  }

  /**
   * @summary Get a Formula Item
   * @param {string} id - ID of the item
   */
  public getItem(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/items/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaItemOutputV1>;
  }

  /**
   * @summary Get details of a specific package
   * @param {string} packageName - The name of the package
   */
  public getPackage(
    {
      packageName
    } : {
      packageName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/packages/${encodeURIComponent(String(packageName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaPackageOutputV1>;
  }

  /**
   * @summary Get a collection of packages
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getPackages(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/packages`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Create or update a package of UDFs with documentation.
   */
  public importPackage(
    body: models.FormulaPackageImportInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/packages/import`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaPackageImportOutputV1>;
  }

  /**
   * @summary Create or update the specified formula doc
   * @param {string} packageName - The name of the package
   * @param {string} docName - The name of the document for the desired function or index
   */
  public putFormulaDoc(
    body: models.FormulaDocInputV1,
    {
      packageName,
      docName
    } : {
      packageName: string,
      docName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    if (isNil(docName)) {
      throw new Error("'docName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/docs/${encodeURIComponent(String(packageName))}/${encodeURIComponent(String(docName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaDocOutputV1>;
  }

  /**
   * @summary Create or update a package
   * @param {string} packageName - The name of the package
   */
  public putPackage(
    body: models.FormulaPackageInputV1,
    {
      packageName
    } : {
      packageName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(packageName)) {
      throw new Error("'packageName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/packages/${encodeURIComponent(String(packageName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaPackageOutputV1>;
  }

  /**
   * @summary Run a Seeq Formula
   * @param {string} [start] - A string representing the starting index of the data to be returned as an ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm).
   * @param {string} [end] - A string representing the ending index of the data to be returned as an ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm).
   * @param {string} [formula] - The formula to be applied. This or function is required.
   * @param {string} [_function] - The ID of the function item to be computed. This or formula is required.
   * @param {Array<string>} [parameters] - Parameters for the formula. Each parameter should have a format of &#x27;name&#x3D;value&#x27; where &#x27;name&#x27; is the variable identifier, without the leading $ sign, and &#x27;value&#x27; is the ID of an item or one of the following parameter expressions that can be used to access the properties of other items that are parameters: $signal.property(&#x27;name&#x27;) to access any property on an item, $signal.parentProperty(&#x27;name&#x27;) can be used if an item is in a tree to to access any property on the parent, and $signal.ancestors(&#x27;, &#x27;) to return a list of all the ancestors, separated by the specified separator. In all of the above examples &#x27;signal&#x27; would need to be another parameter that references an item using an ID.
   * @param {Array<string>} [fragments] - Formula fragments for unbound inputs the function. Each parameter should have a format of &#x27;name&#x3D;formula&#x27; where &#x27;name&#x27; is the variable identifier, without the leading $ sign, and &#x27;formula&#x27; is a self-contained formula fragment
   * @param {string} [root] - Used to run a formula across assets, this is the ID of the root asset whose immediate children will be iterated. The formula must produce a table.
   * @param {string} [reduceFormula] - Used when running a formula across assets, this is a formula that can further reduce the results of each asset result. The variable $result must be used to reference the data. Example of sorting the aggregated results: $result.sort(&#x27;temperature&#x27;)&#x27;
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {string} [continuationToken] - An opaque token used to query for the next page of results. Only use if returned by a previous call.
   * @param {string} [timeFormat=Legacy]
   */
  public runFormula(
    {
      start,
      end,
      formula,
      _function,
      parameters,
      fragments,
      root,
      reduceFormula,
      limit,
      continuationToken,
      timeFormat
    } : {
      start?: string,
      end?: string,
      formula?: string,
      _function?: string,
      parameters?: Array<string>,
      fragments?: Array<string>,
      root?: string,
      reduceFormula?: string,
      limit?: number,
      continuationToken?: string,
      timeFormat?: TimeFormatEnum
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/formulas/run`,

      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['formula']: formula,
        ['function']: _function,
        ['parameters']: parameters,
        ['fragments']: fragments,
        ['root']: root,
        ['reduceFormula']: reduceFormula,
        ['limit']: limit,
        ['continuationToken']: continuationToken,
        ['timeFormat']: timeFormat
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaRunOutputV1>;
  }

  /**
   * @summary Run a Seeq Formula.
   */
  public runFormula_1(
    body: models.FormulaRunInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/run`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FormulaRunOutputV1>;
  }

  /**
   * @summary Update a function
   * @param {string} id - ID of the function to update
   */
  public updateFunction(
    body: models.FunctionInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/formulas/functions/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CalculatedItemOutputV1>;
  }

}

export enum TimeFormatEnum {
    Legacy = 'Legacy' as any,
    ISO8601 = 'ISO8601' as any,
    Nanoseconds = 'Nanoseconds' as any
}

export const sqFormulasApi = new sqFormulasApiClass();
