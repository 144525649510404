import { SeeqNames } from '@/main/app.constants.seeqnames';

export const SEARCH_PER_PAGE = 35;
export const MAX_RETURNED_RESULTS = 1000;
export type SearchPanes = 'main' | 'modal';
export type SearchModes =
  | 'overview'
  | 'search'
  | 'tree'
  | 'pinned'
  | 'recent'
  | 'asset-groups'
  | 'usages'
  | 'table-definitions';

export const SORT_BY_OPTIONS = {
  DEFAULT: '',
  NAME: 'Name',
  DESCRIPTION: 'Description',
};
export const SEARCH_BREADCRUMB = {
  name: 'SEARCH_DATA.SEARCH_RESULTS',
  id: 'SEARCH',
  type: 'VIEW_MODE',
};
export const HOME_BREADCRUMB = { id: '' };
export const SEARCH_USAGES_BREADCRUMB = {
  name: 'SEARCH_DATA.USAGES',
  id: 'ITEM_USAGES',
  type: 'VIEW_MODE',
};
export const SEARCH_RESULT_TYPES = {
  ITEMS: 'items',
  ASSETS: 'assets',
};

export const DATASOURCES_TO_EXCLUDE = ['Auth', 'LDAP'];

export const OPERATOR_OPTIONS = [
  { value: 'EQUALS', text: 'EQUALS' },
  { value: 'EQUALS_IGNORE_CASE', text: 'EQUALS_IGNORE_CASE' },
  { value: 'NOT_EQUALS', text: 'NOT_EQUALS' },
  { value: 'WITHIN', text: 'ITEM_FINDER.PROPERTY_OPERATORS.WITHIN' },
  { value: 'STRING_CONTAINS', text: 'ITEM_FINDER.PROPERTY_OPERATORS.STRING_CONTAINS' },
];

export const DEFAULT_SEARCH_PROPERTY_OPTIONS = [
  { value: SeeqNames.Properties.Name, text: 'ITEM_FINDER.PROPERTY_SELECT_NAME' },
  { value: SeeqNames.Properties.Description, text: 'ITEM_FINDER.PROPERTY_SELECT_DESCRIPTION' },
  { value: SeeqNames.Properties.DatasourceId, text: 'ITEM_FINDER.PROPERTY_SELECT_DATASOURCE_ID' },
  { value: SeeqNames.Properties.DatasourceName, text: 'ITEM_SEARCH.PROPERTY.DATASOURCE_NAME' },
  { value: 'parent', text: 'ITEM_SEARCH.PROPERTY.PARENT' },
];
